<template>
    <div>
        <!-- Breadcrumbs -->
        <v-container v-if="smAndUp">
            <v-row>
                <v-col>
                    <v-breadcrumbs
                        :items="breadCrumbs"
                        divider=">"
                    ></v-breadcrumbs>
                </v-col>
            </v-row>
        </v-container>

        <!-- パーソナルトレーニング -->
        <v-container>
            <v-row justify="center">
                <v-col cols="auto" align="center">
                    <h1><heading>パーソナルトレーニング</heading></h1>
                </v-col>
                <v-col cols="12" align="center">
                    <v-img
                        :src="imageWhatsRevoist"
                        :key="imageWhatsRevoist"
                    ></v-img>
                </v-col>
                <v-col cols="auto" align="center">
                    <div
                        class="font-weight-light"
                        :class="smAndUp ? 'text-h4 my-4 ' : 'text-h6 my-4'"
                    >
                        効果が実感できない<br />運動を続けられない、<br />
                        カラダを変えられない<br /><br /><br />
                        そんなあなたへ
                    </div>
                </v-col>
                <v-col
                    cols="auto"
                    md="10"
                    align="left"
                    class="font-weight-light"
                    :class="smAndUp ? 'text-h5' : 'text-h6'"
                >
                    指導経験豊富なREVOISTのパーソナルトレーナーが科学の知見に基づきカラダの悩みを解決します。
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col md="10">
                    <v-card flat>
                        <heading> カラダの本質 </heading>
                        <v-col
                            align="left"
                            :class="smAndUp ? 'text-h5' : 'text-h6'"
                            class="px-0 font-weight-light"
                        >
                            カラダの本質は『現在のカラダ＝現在の生活習慣』です。
                            もし、あなたが現在のカラダを変えたいのならば、現在のあなたの生活習慣を変えるしかありません。<br />
                            「○○を食べるだけで」など、世の中には都合のいいダイエット情報が溢れていますがそれに振り回されてこれまでに何か手に入りましたか？
                            特に、ダイエットやボディメイクには、運動習慣と食習慣を見直す必要があります。
                        </v-col>
                        <heading>REVOISTのサービス</heading>
                        <v-col
                            align="left"
                            :class="smAndUp ? 'text-h5' : 'text-h6'"
                            class="px-0 font-weight-light"
                        >
                            REVOISTは、現在のあなたの生活習慣に
                            『週1回の効果的な筋トレ』と『毎日の規則正しい食事』
                            を組み込むことでダイエットやボディメイクを成功に導きます。
                        </v-col>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <!-- Training Items -->
        <v-container>
            <v-row>
                <v-col
                    cols="12"
                    md="6"
                    v-for="item in items"
                    :key="item.imageUrl"
                >
                    <heading>{{ item.title }}</heading>
                    <v-img :src="item.imageUrl"></v-img>
                    <div
                        class="text-body-1 ma-2"
                        v-html="item.description"
                    ></div>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col md="8">
                    <link-tag compName="Price">料金・会員種別</link-tag>
                </v-col>
            </v-row>
        </v-container>
        <footer-contents></footer-contents>
    </div>
</template>

<script>
import Heading from '@/components/Heading.vue'
import LinkTag from '@/components/LinkTag.vue'
import FooterContents from '@/components/FooterContents.vue'

export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
    },
    components: {
        Heading,
        LinkTag,
        FooterContents,
    },
    data() {
        return {
            items: [
                {
                    imageUrl:
                        '/static/pc_personal-training/pixta_47085818_XL.png',
                    title: '筋トレ',
                    description:
                        'あなたの体力やトレーニングレベルと目的に応じたメニューで、 正しく、わかりやすく、丁寧に、安全に、効果的な筋トレをします。 健康的に美しく、引き締まった筋肉をつけ、メリハリのあるカラダを！',
                },
                {
                    imageUrl:
                        '/static/pc_personal-training/pixta_58654152_XL.png',
                    title: 'コンディショニング',
                    description:
                        '姿勢や関節の可動域をチェックし、ストレッチや筋膜リリースなどで腰痛改善、肩こり解消、姿勢改善でしなやかに動くカラダを！',
                },
                {
                    imageUrl:
                        '/static/pc_personal-training/pixta_59971386_XL.png',
                    title: 'カウンセリング',
                    description:
                        'あなたの悩み、生活習慣、性格、課題にしっかりと耳を傾け、 ベストな解決方法を提案し、無理なく楽しみながら一緒に生活習慣の構築を！',
                },
                {
                    imageUrl: '/static/pc_personal-training/トレーニング04.png',
                    title: '食事アドバイス',
                    description:
                        '糖質制限や禁酒などはしません。 あなたにとって無理のある食習慣ではなく、知識をもって栄養を正しく選べるように！',
                },
                {
                    imageUrl: '/static/pc_personal-training/トレーニング05.png',
                    title: '自宅トレーニングメニュー作成',
                    description:
                        '自宅でひとりでもできるようなストレッチや自重トレーニングで さらにプラスαの効果を！',
                },
            ],
        }
    },
    computed: {
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: 'パーソナルトレーニング',
                    disabled: true,
                    href: 'personal-training',
                },
            ]
        },
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        imageWhatsRevoist() {
            const path = this.$vuetify.breakpoint.smAndUp
                ? '/static/pc_personal-training/pixta_64995213_XL.png'
                : '/static/sp_personal-training/personal_img01.png'
            return path
        },
    },
}
</script>

<style lang="scss" scoped></style>
